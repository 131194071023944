import { GET_RATES, NEWS_ERROR, SET_LOADING } from "./types";
import { server } from "../constants/server";

export const getRates = () => async dispatch => {
  try {
    setLoading();
    const res = await fetch(
      server.address + "/rates/api"
    );
    const data = await res.json();
    dispatch({
      type: GET_RATES,
      payload: data
    });
  } catch (e) {
    dispatch({
      type: NEWS_ERROR,
      payload: e
    });
  }
};
export const setLoading = () => {
  return {
    type: SET_LOADING
  };
};
